import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import AddToCalendar from "react-add-to-calendar";
import moment from "moment/moment";
import momentTz from "moment-timezone";
// import { isSafari, isMobileSafari } from 'react-device-detect';
// import { WEBCASTING_PLATFORM_URL } from "../config/config";

import "react-add-to-calendar/dist/react-add-to-calendar.css";
import "./index.css";
import { BsGoogle, BsApple, BsMicrosoft } from "react-icons/bs";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import CookieConsent from "react-cookie-consent";
import QuestionIcon from "@material-ui/icons/QuestionAnswer";
import Countdown from "react-countdown";
import WebcastingContext from "../context/webcasting/webcastingContext";

// HOOKS & HELPERS
import useLogo from "../hooks/useLogo";
// import useTimeDateConversion from '../hooks/useTimeDateConversion';
import { gaEvent } from "../helpers/reactGA";
import {
  handleGenerateCalendarLink,
  handleGenerateCalendarLinkGoogle,
  // getFullDate,
  // getTime,
  // getBSTorGMT,
  styleReleaseDate,
  titleCase,
} from "../helpers";

// Components
import HoldingVideo from "../components/players/video/HoldingVideo";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { CalendarToday, CalendarViewDayRounded } from "@material-ui/icons";
const BackgroundImg = lazy(() => import("../components/UI/BackgroundImg"));
const Logo = lazy(() => import("../components/UI/Logo"));
const QuestionsDrawer = lazy(() => import("../components/questions"));
const ShareButtons = lazy(() => import("../components/UI/ShareButtons"));



const UpcomingEvent = (props) => {

  const webcastingContext = useContext(WebcastingContext)

  const {setGA,event} = webcastingContext
  let companyId = event.items[0].companies[0]._id
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "90%",
      maxWidth: "600px",
      display: "inline-block",
    },
    // opacity: {
    //   backgroundColor: 'rgba(255, 255, 255, 0.8)',
    // },
    mainContent: {
      padding: "8px 24px",
      marginBottom: "1.5rem",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0.85rem",
      },
    },
    gridItemWidth: {
      width: "100%",
    },
    actionArea: {
      flexDirection: "column",
      padding: "0px 24px 8px",
      [theme.breakpoints.down("400")]: {
        padding: "0px",
      },
    },
    cName: {
      marginTop: "1rem",
      marginBottom: "2rem",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "1rem",
      },
      [theme.breakpoints.down("400")]: {
        fontSize: "1.5rem",
      },
    },
    date: {
      margin: "1.7rem 0px",
      color: "var(--clr-g-7e)",
      [theme.breakpoints.down("xs")]: {
        margin: "1.7rem 0px",
      },
      "& span": {
        fontSize: "1rem",
      },
    },
    btnsContainer: {
      width: "100%",
      display: props.match.location.pathname.includes("649ec23ed907fa0b867b55e6") && feedbackBtn ? 'flex' : (props.match.location.pathname.includes("649ec23ed907fa0b867b55e6")? 'none' : 'flex'),
      justifyContent: "space-around",
      alignItems: "center",
      flexWrap: "wrap",
      marginBottom: "1rem",
      [theme.breakpoints.down("650")]: {
        textAlign: "center",
        "& a": {
          marginBottom: "2rem",
        },
      },
      [theme.breakpoints.down("400")]: {
        padding: "0px",
      },
    },
    questionBtnLink: {
      color: "#fff",
      backgroundColor: "#333",
      "&:hover": {
        backgroundColor: "#333",
      },
    },
  
    menuItemWithIcons:{
      display: 'flex', justifyContent: 'center', alignContent: 'center', gap: '0.4rem', alignItems:'center'
    },
    countdown: {
      marginTop: '0.5rem',
      marginLeft: "0px !important",
      marginBottom: "1.5rem",
      "& h1": {
        fontSize: "28px",
        color: "#333",
      },
      "& p": {
        fontSize: "0.9rem",
      },
      [theme.breakpoints.down("xs")]: {
        marginBottom: "2rem",
        "& h1": {
          fontSize: "28px",
        },
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: "40%",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "60%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "75%",
      },
    },
  }));




  const baseUrl = process.env.REACT_APP_WEBCASTING_PLATFORM_URL;

  const [authUrl, setAuthUrl] = useState("");
  const [drawerQuestionsState, setDrawerQuestionsState] = useState({
    left: false,
  });
  const [feedbackBtn, setFeedbackBtn] = useState(false);
  const [shareBtns, setShareBtns] = useState(false);
  const [eventID, setEventID] = useState("");
  const [title, setTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [eventStartTime, setEventStartTime] = useState(null)
  const [eventEndTime, setEventEndTime] = useState(null)

  // const [eventFullDate, setEventFullDate] = useState('');
  // const [eventTime, setEventTime] = useState('');
  // const [bstGmtTime, setBstGmtTime] = useState('');
  const [brandingFound, setBrandingFound] = useState(false);
  const [logoPath, logoPadding] = useLogo(props.loading, props.event);
  const [caledarEvent, setCalendarEvent] = useState({});
  // const dateTimeString = useTimeDateConversion(
  //   props.event.items[0].releaseTime
  // );
  const [bgOpacity, setBgOpacity] = useState(false);
  const [newDateFormat, setNewDateFromat] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickCalendar = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  useEffect(() => {
    setAuthUrl(props.match.match.url);
    setFeedbackBtn(props.event.items[0].webcast.feedbackEnabled);
    setShareBtns(props.event.items[0].webcast.shareButtons);
    setEventID(props.event.items[0]._id);
    setTitle(props.event.items[0].title);
    setEventDescription(props.event.items[0].description);
    setCompanyName(titleCase(props.event.items[0].companies[0].name));
    // if (!isSafari) {
    //   setEventFullDate(getFullDate(props.event.items[0].releaseTime));
    // }
    // setEventTime(getTime(props.event.items[0].releaseTime));
    // setBstGmtTime(getBSTorGMT(props.event.items[0].releaseTime));
    if (props.event.items[0].webcast.holdingCardTransparency) {
      setBgOpacity(props.event.items[0].webcast.holdingCardTransparency);
    }
    setNewDateFromat(
      styleReleaseDate(
        moment(props.event.items[0].releaseTime).format(
          `${props.event.items[0].dateFormat}`
        ),
        props.event.items[0].releaseTime
      )
    );

    setTimeZone(
      momentTz
        .tz(
          `${props.event.items[0].releaseTime}`,
          `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
        )
        .format("z")
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBrandingFound(props.brandingFound);
  }, [props.brandingFound]);

  //////////////////// SET CALENDAR, DROPDOWN MENU AND ICON
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      let startTime = null;
      let endTime = null;

      console.log("Count down", props.event.items[0].webcast.countdownTimer);
      // const startTime = moment(props.event.items[0].releaseTime).format(
      //   'YYYY-MM-DDTHH:mm:ss.SSSZ'
      // );

      if (moment(props.event.items[0].releaseTime).isValid()) {
        startTime = moment(props.event.items[0].releaseTime).format(
          "YYYY-MM-DDTHH:mm:ss.SSSZ"
        );
        setEventStartTime(momentTz.tz( `${props.event.items[0].releaseTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())
      } else {
        startTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        setEventStartTime(momentTz.tz( `${props.event.items[0].releaseTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())

      }

      if (props.event.items[0].endTime) {
        if (moment(props.event.items[0].endTime).isValid()) {
          endTime = moment(props.event.items[0].endTime).format(
            "YYYY-MM-DDTHH:mm:ss.SSSZ"
          );
          setEventEndTime(momentTz.tz(`${props.event.items[0].endTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())
        } else {
          endTime = moment(startTime)
            .add(1, "hours")
            .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        }
        setEventEndTime(momentTz.tz(`${props.event.items[0].endTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())

      } else {
        endTime = moment(startTime)
          .add(1, "hours")
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
        setEventEndTime(momentTz.tz(`${props.event.items[0].endTime}`, `${Intl.DateTimeFormat().resolvedOptions().timeZone}`).format())

      }

      if (   props.match.location.pathname.includes("6335b1e7fb246c0809c9add1") || props.match.location.pathname.includes("6419a6d337aa9c3b31482824") 
       ||  props.match.location.pathname.includes("651d6c9d8fb8fe0aea8cd599") ||  props.match.location.pathname.includes("65eefad549f20f9f1fea18cf")
       )  
       {  
        setCalendarEvent({
          title: `${companyName} - ${title}`,
          description: `${eventDescription} Please join the webcast here: ${baseUrl}${authUrl}`,
          startTime: startTime,
          endTime: endTime,
          location: "BRR Media Webcasting Platform",
        });
      } else if(props.match.location.pathname.includes("64d2657207eccc4a190b9af7")) {
        setCalendarEvent({
          title: `${companyName} - ${title}`,
          description: `Join us as we present our performance highlights from our Responsible Business Report 2022/23, as well as the key updates we’ve made to our Responsible Business Charter.
          Speakers include: CEO, John Pettigrew; Chief Sustainability Officer, Rhian Kelly and NY President, Rudy Wynter`,
          startTime: startTime,
          endTime: endTime,
          location: "BRR Media Webcasting Platform",
        });
      }
      
      
      else {
        setCalendarEvent({
          title: title,
          // description: `${eventDescription}\n<a href='${baseUrl}${authUrl}'>${companyName} - ${title}</a>`,
          description: `${eventDescription} Please join the webcast here: ${baseUrl}${authUrl}`,
          startTime: startTime,
          endTime: endTime,
          location: "BRR Media Webcasting Platform",
        });
      }
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [title, eventDescription]);

  let icon = { "calendar-plus-o": "left" };
  let items = [
    { apple: "Apple Calendar" },
    { google: "Google" },
    { outlook: "Outlook" },
  ];

  // COUNTDOWN
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      if (props.event.items[0].isLive === false) {
        props.countDownCompleted();
      }
      return <span>The Event is due to start</span>;
    } else {
      // Render a countdown
      return (
        <>
          <p className="description">Your webcast is due to start in</p>
          <h1 className="title">
            {days > 0 ? (
              <span>
                {days}
                <span className="dhms">d</span>{" "}
              </span>
            ) : (
              <span></span>
            )}
            {hours}
            <span className="dhms">h</span> {minutes}
            <span className="dhms">m</span> {seconds}
            <span className="dhms">s</span>
          </h1>
        </>
      );
    }
  };

  const toggleDrawerQuestions = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerQuestionsState({ ...drawerQuestionsState, [anchor]: open });
  };

  const classes = useStyles();





  return (
    <>
      <Suspense fallback={<></>}>
        <>
          {props.event.items[0].holdingSlide !== null ? (
            <BackgroundImg
              path={props.event.items[0].holdingSlide.path}
              img={true}
              colour={null}
            />
          ) : props.event.items[0].webcast.colour.background !== null ? (
            <BackgroundImg
              path={null}
              img={false}
              colour={props.event.items[0].webcast.colour.background}
            />
          ) : (
            <></>
          )}
        </>
        <CssBaseline />
        <Container
          maxWidth="xl"
          className="mainContainer"
          align="center"
          justify="center"
        >
          <Grid
            container
            id="secondRow"
            alignContent="center"
            justify="center"
            spacing={0}
            direction="column"
            alignItems="center"
            style={{ minHeight: "100vh" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.gridItemWidth}
            >
              <Card
                className={`${classes.root} ${bgOpacity ? "bgLogoTransparency" : ""
                  } `}
              >
                <CardActionArea>
                  {logoPath && (
                    <Logo
                      src={logoPath}
                      title={props.event.items[0].title}
                      logoPadding={logoPadding}
                      backgroundColor="#fff"
                    // backgroundColor={logoBackground}
                    />
                  )}
                  <CardContent className={classes.mainContent}>
                    {brandingFound ? (
                      <>
                        <p
                          className="title branding-title"
                          // style={{
                          //   fontFamily:
                          //     props.event.items[0].webcast.branding.fonts[0]
                          //       ?.fontFamily,
                          //   color:
                          //     props.event.items[0].webcast.branding.fonts[0]
                          //       ?.fontColor,
                          //   fontSize:
                          //     props.event.items[0].webcast.branding.fonts[0]
                          //       ?.fontSizeBase,
                          //   fontWeight:
                          //     props.event.items[0].webcast.branding.fonts[0]
                          //       ?.fontWeight,
                          // }}
                        >
                          {props.event.items[0].title}
                        </p>

                        {typeof props.event.items[0].holdingVideoUrl !==
                          "undefined" &&
                          props.event.items[0].holdingVideoUrl.length > 0 &&
                          props.event.items[0].holdingVideoUrl[0] !== "" && (
                            <>
                              {/* <Typography variant="caption">
                               Watch the below video to test your video
                                and audio playback.
                              </Typography> */}
                              {props.event.items[0]._id === '66f683e482620abb9589014b' ?
                              <p className="description branding-title-upcoming">
                                Watch the video below to view a high level agenda of the day and to test your video and audio playback
                              </p> :
                              <p className="description branding-title-upcoming">
                              Watch the below video to test your video
                              and audio playback.
                            </p>
                              }
                              

                              <HoldingVideo
                                srcData={
                                  props.event.items[0].holdingVideoUrl[0]
                                }
                              />
                            </>
                          )}

                        {props.match.location.pathname.includes(
                          "6335b1e7fb246c0809c9add1"
                        ) && (
                            <>
                              <HoldingVideo srcData="https://brrmediauk-brrmedia-assets.s3.amazonaws.com/holding-video/Introducing+Project+C.mp4" />
                              <p className="description branding-title-upcoming">
                                Watch the below video to test your video
                                and audio playback.
                              </p>
                              {/* <Typography variant="caption">
                              Please test the video above to make sure your
                              browsers streaming settings are working.
                            </Typography> */}
                            </>
                          )}

                        {newDateFormat &&
                          timeZone &&
                          (props.match.location.pathname.includes(
                            "6335b1e7fb246c0809c9add1"
                          ) ? (
                            <Typography
                              variant="h6"
                              component="h6"
                              className={classes.date}
                            >
                              {newDateFormat.onlyDate} - 0
                              {newDateFormat.hrsMins}
                              <span> ({timeZone})</span>
                            </Typography>
                          ) : (
                            <Typography
                              variant="h6"
                              component="h6"
                              // className={classes.date}
                              className={'title'}

                            >

                              {
                                  props.match.location.pathname.includes("64d2657207eccc4a190b9af7") 
                            
                                  ? 'Thursday, 21st September 2023, 15:00 PM (BST) / 10:00 AM (EST)'
                                :
                              moment(props.event.items[0].releaseTime).format(
                                `${props.event.items[0].dateFormat}`
                              )
                              
                              }

                                {
                                   !props.match.location.pathname.includes("64d2657207eccc4a190b9af7") && <span> ({timeZone})</span>

                                }
                          
                            </Typography>
                          ))}

                        {/* {isSafari || isMobileSafari ? (
                          <Typography
                            variant='h6'
                            component='h6'
                            className={classes.date}
                          >
                            {dateTimeString &&
                              timeZone &&
                              `${dateTimeString} (${timeZone})`}
                          </Typography>
                        ) : (
                          newDateFormat &&
                          timeZone && (
                            <Typography
                              variant='h6'
                              component='h6'
                              className={classes.date}
                            >
                              {newDateFormat.onlyDate} - {newDateFormat.hrsMins}
                              {!props.match.location.pathname.includes(
                                '6335b1e7fb246c0809c9add1'
                              ) && (
                                <>
                                  <span>{newDateFormat.suffix}</span>
                                </>
                              )}
                              <span> ({timeZone})</span>
                            </Typography>
                          )
                        )} */}
                          {
                            props.match.location.pathname.includes("64d2657207eccc4a190b9af7") 
                            
                            ? 
                            eventDescription !== "" && (
                              <>

                              <p className="description branding-title-upcoming" style={{paddingBottom:0,textAlign:'justify', marginTop:'0.5rem',  fontSize:'14px'}}>
                              Join us as we present our performance highlights from our Responsible Business Report 2022/23, as well as the key updates we’ve made to our Responsible Business Charter.
                             
                              </p>
                              <p className="description" style={{fontWeight:'bold',fontSize:'14px',textAlign:'justify', marginTop:'0.2rem', marginTop:'0.5rem',marginLeft:'1.5rem'}}>
                              Speakers include:</p>
                              <p className="description" style={{ textAlign:'left', fontSize:'14px',marginLeft:'2rem'}}>
                              - CEO, John Pettigrew</p>
                              <p className="description" style={{ textAlign:'left', fontSize:'14px', marginLeft:'2rem'}}>
                              - Chief Sustainability Officer, Rhian Kelly</p>
                             <p className="description" style={{ textAlign:'left', fontSize:'14px', marginLeft:'2rem'}}>
                               - NY President, Rudy Wynter </p>
                              </>
                            )
    
                            :    eventDescription !== "" && (

                              <p className="description branding-title-upcoming">
                                    {eventDescription.split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                      {line}
                                      <br />
                                    </React.Fragment>
                                  ))}
                                {/* {eventDescription} */}
                              </p>
                            )
    
                          }
                    



                      </>
                    ) : (
                      <>
                        <Typography gutterBottom variant="h5" component="h1">
                          {props.event.items[0].title}
                        </Typography>

                        {newDateFormat && timeZone && (
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.date}
                          >
                            {newDateFormat.onlyDate} - {newDateFormat.hrsMins}
                            <span>{newDateFormat.suffix}</span>{" "}
                            <span>({timeZone})</span>
                          </Typography>
                        )}

                        {/* {isSafari || isMobileSafari ? (
                          <Typography
                            variant='h6'
                            component='h6'
                            className={classes.date}
                          >
                            {dateTimeString &&
                              timeZone &&
                              `${dateTimeString} (${timeZone})`}
                          </Typography>
                        ) : (
                          newDateFormat &&
                          timeZone && (
                            <Typography
                              variant='h6'
                              component='h6'
                              className={classes.date}
                            >
                              {newDateFormat.onlyDate} - {newDateFormat.hrsMins}
                              {!props.match.location.pathname.includes(
                                '6335b1e7fb246c0809c9add1'
                              ) && (
                                <>
                                  <span>{newDateFormat.suffix}</span>{' '}
                                </>
                              )}
                              <span>({timeZone})</span>
                            </Typography>
                          )
                        )} */}
                        {eventDescription !== "" && (
                          <Typography
                            gutterBottom
                            variant="body1"
                            component="p"
                          >
                            {eventDescription}
                          </Typography>
                        )}
                      </>
                    )}
                  </CardContent>
                </CardActionArea>
                <CardActions className={classes.actionArea}>
                  <CardContent className={classes.btnsContainer}>
                    {feedbackBtn && (
                      <>
                        <Link
                          target="_blank"
                          underline="none"
                          onClick={toggleDrawerQuestions(`left`, true)}
                        >
                          <Button
                            className={classes.questionBtnLink}
                            variant="contained"
                            size="large"
                            startIcon={<QuestionIcon />}
                            onClick={() =>
                              gaEvent(
                                "submit_question",
                                "open",
                                "open question form"
                              )
                            }
                            disableElevation
                          >
                            Submit Questions
                          </Button>
                        </Link>
                      </>
                    )}
              { !props.match.location.pathname.includes("649ec23ed907fa0b867b55e6") &&
                <Grid>

                           <Button
                           className={classes.questionBtnLink}
                           startIcon={<CalendarToday />}
     
                           variant="contained"
                           size="large"
                           id="demo-positioned-button"
                           aria-controls={open ? 'demo-positioned-menu' : undefined}
                           aria-haspopup="true"
                           aria-expanded={open ? 'true' : undefined}
                           onClick={handleClickCalendar}
                         >
                           Add To Calendar
                         </Button>


                         {props.event.items[0]._id === '66f683e482620abb9589014b' &&
                         
                         <Card style={{
                          padding: 20, 
                          margin: 10, 
                          textAlign: 'left', 
                          background:'#0f2b64', 
                          color:'#ffffff'
                          }}>
                         <h3 className="title" style={{marginBottom:10, color:'#ffffff'}}>The webcast will Broadcast from:</h3>
                         <p className="description" style={{color:'#ffffff', marginBottom:5}}>08:30 - 12:15: Presentations (webcast)</p>
                         <p className="description" style={{color:'#ffffff', marginBottom:5}}>12:15 - 14:55: Lab Tours and Interactive Exhibition (in-person only)</p>
                         <p className="description" style={{color:'#ffffff', marginBottom:5}}>14:55 - 17:00: Presentations (webcast)</p>
                         </Card>
                         }
             </Grid>

                        
                         
              }

                  </CardContent>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}


                  >
                    {/* type,startTime, endTime, location, title, description */}
                    <MenuItem onClick={() => {
                      handleGenerateCalendarLink('apple', eventStartTime, eventEndTime, '',

                      companyId === '531e549c3665390df9004a7b' ?
                      `${companyName} - ${title}` : title,

                      props.match.location.pathname.includes("65d5e7bd35af67d51a41a476") ? 
                      `XP Power will be announcing its results for the twelve months ending 31 December 2023 on Tuesday 5 March 2024 \nAn analyst presentation hosted by Gavin Griggs, CEO, and Matt Webb, CFO, will be held at 10:30am on the day.\n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`
                      : `${eventDescription} \n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`);

                      //  `${eventDescription} \n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`);


                      handleClose()

                    }
                    }
                    className={classes.menuItemWithIcons}
                    >
                      {/* <Box style={{display:'flex', justifyContent:'center', alignContent:'center'}}> */}
                      <BsApple fontSize={'1.2rem'}/>
                      <Typography style={{ textAlign: 'center', fontSize:'1rem' }}>
                        Apple
                      </Typography>
                      {/* </Box> */}

                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleGenerateCalendarLink('google',
                      eventStartTime,
                      eventEndTime, 
                      '', 
                      companyId === '531e549c3665390df9004a7b' ?
                      `${companyName} - ${title}` : title, 

                      props.match.location.pathname.includes("65d5e7bd35af67d51a41a476") ? 
                      `XP Power will be announcing its results for the twelve months ending 31 December 2023 on Tuesday 5 March 2024 \nAn analyst presentation hosted by Gavin Griggs, CEO, and Matt Webb, CFO, will be held at 10:30am on the day.\n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`
                      : `${eventDescription} \n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`);

                      // `${eventDescription} \n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`);

                      handleClose()
                    }
                    }
                    className={classes.menuItemWithIcons}

                    >
                      <BsGoogle  fontSize={'1.2rem'}/>
                      <Typography style={{ textAlign: 'center', fontSize:'1rem' }}>
                        Google
                      </Typography>

                    </MenuItem>
                    <MenuItem onClick={() => {
                      handleGenerateCalendarLink('outlook', 
                      eventStartTime,
                      eventEndTime, 
                      '', 
                      companyId === '531e549c3665390df9004a7b' ?
                       `${companyName} - ${title}` : title, 

                       props.match.location.pathname.includes("65d5e7bd35af67d51a41a476") ? 
                       `XP Power will be announcing its results for the twelve months ending 31 December 2023 on Tuesday 5 March 2024 \nAn analyst presentation hosted by Gavin Griggs, CEO, and Matt Webb, CFO, will be held at 10:30am on the day.\n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`
                       : `${eventDescription} \n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`);
                       
                      //  `${eventDescription} \n\nTo join the webcast, please use the following link: ${baseUrl}${authUrl}`);
                      handleClose()

                    }
                    }
                    className={classes.menuItemWithIcons}

                    >

                      <BsMicrosoft fontSize={'1.2rem'}/>
                      <Typography style={{ textAlign: 'center', fontSize:'1rem' }}>
                        Outlook
                      </Typography>
                    </MenuItem>
                  </Menu>
                  {/* check if the countdown is true  */}

                  {props.event.items[0].webcast.countdownTimer && (
                    <Typography component="div" className={classes.countdown}>
                      <Countdown
                        date={Date.now() + props.timeToEvent}
                        renderer={renderer}
                        zeroPadTime={2}
                      />
                    </Typography>
                  )}

                  <div className="share-btns-section">
                    {shareBtns && (
                      <ShareButtons
                        eventID={eventID}
                        title={title}
                        description={eventDescription}
                        companyName={companyName}
                      />
                    )}
                  </div>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
        {!props.match.location.search.includes("template=clean") && ( !props.event.items[0].registrationRequired) &&  (

          <CookieConsent
          enableDeclineButton
          buttonText="Agree"
          declineButtonText="Disagree"
          onAccept={()=>{
            setGA(true)
          }}
          onDecline={() => {
            alert("nay!");
          }}

            cookieName="mySiteCookieConsent"
            location="top"
            style={{ background: "#e3e3e3", color: "#2B373B", zIndex: 99000 }}
          >
            We use cookies to provide and improve our services. By using our
            site you consent to{" "}
            <a href="../../../../../cookies" target="_blank">
              cookies
            </a>
          </CookieConsent>

        )}
        <Drawer
          anchor={`left`}
          open={drawerQuestionsState[`left`]}
          onClose={toggleDrawerQuestions(`left`, false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <QuestionsDrawer
            className={classes.toolbar}
            drawerQuestionsState={{ left: false }}
            event={props.event.items[0]}
            pathName={props.match.location.pathname}
            onClose={toggleDrawerQuestions(`left`, false)}
          />
        </Drawer>
      </Suspense>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps === nextProps;
};

export default React.memo(UpcomingEvent, areEqual);
